<script lang="ts">
  import { pickDestination } from "../../stores/destination-store";
  import { applyFilter } from "../../stores/feature-filter-store";
  import { pickOrigin } from "../../stores/origin-store";
  import { viewerCountry } from "../../stores/viewer-context-store";
  import type { FeatureFilter } from "../integration/mapbox-filter";

  export let destinationSlug: string | undefined = undefined;
  export let originSlug: string | undefined = undefined;
  export let filter: FeatureFilter | undefined = undefined;

  originSlug && pickOrigin(originSlug);
  destinationSlug && pickDestination(destinationSlug);

  if (filter) {
    applyFilter(filter);
  } else {
    viewerCountry.subscribe((countryCode) => {
      applyFilter({ countryCode });
    });
  }
</script>
